/* ---------------- */
/* Custom Variables */
/* ---------------- */

// Grid system
$-grid-width: 1600px;
$-grid-gutter: 16px;
$-padding-unit: 8px;

$-border-radius: 8px;

// Application colors
$-color-white: #ffffff;
$-color-dark: #1b2437;
$-color-grey: #dadbdf;
$-color-silver: #dce2e6;
$-color-light-grey: #e0e1e4;

$-color-success: #55a530;
$-color-warning: #ff9b33;
$-color-error: #ff0000;

$-color-body: #f5f8fa;
$-color-body-2: mix($-color-white, $-color-body, 80%); // Secondary button custom type
$-color-body-5: mix($-color-white, $-color-body, 50%);

$-color-primary: #454b60;
$-color-primary-1: mix($-color-white, $-color-primary, 90%); // 0.1 opacity - 90% white blend
$-color-primary-2: mix($-color-white, $-color-primary, 80%); // 0.2 opacity - 80% white blend
$-color-primary-3: mix($-color-white, $-color-primary, 70%);
$-color-primary-4: mix($-color-white, $-color-primary, 60%);
$-color-primary-5: mix($-color-white, $-color-primary, 50%);
$-color-primary-6: mix($-color-white, $-color-primary, 40%);
$-color-primary-7: mix($-color-white, $-color-primary, 30%);
$-color-primary-8: mix($-color-white, $-color-primary, 20%);
$-color-primary-9: mix($-color-white, $-color-primary, 10%);

$-color-ACCOUNTABILITY: #e7adff;
$-color-ADAPTABILITY: #fec424;
$-color-DECISION_MAKING: #9de589;
$-color-FLEXIBILITY: #58b5ff;
$-color-INTEGRITY: #f67280;
$-color-LEARNING_AGILITY: #a99bfb;
$-color-RESILIENCE: #f38531;
$-color-TEAMWORK: #fce0c4;

$-color-orange: #fec424; // Game button custom type
$-color-orange-3: mix($-color-white, $-color-orange, 70%);
$-color-orange-5: mix($-color-white, $-color-orange, 50%);

// Text colors
$-font-color-primary: $-color-primary;
$-font-color-secondary: #909399;

// Typography
$-font-size-xxxl: 36px;
$-font-size-xxl:  24px;
$-font-size-xl:   21px;
$-font-size-l:    16px;
$-font-size-m:    14px;
$-font-size-s:    12px;
$-font-size-xs:   11px;
$-font-size-xxs:  10px;

// Font awesome path, required
$fa-font-path: "~font-awesome/fonts";

/* -------------------------- */
/* Element variables override */
/* -------------------------- */
$--font-size-small: $-font-size-s;

// el-header padding
$--header-padding: 0 $-grid-gutter;

// el-main padding
$--main-padding: 0;

// theme color
$--color-primary: $-color-primary;

$--button-padding-horizontal: $-grid-gutter;

// theme font colors
$--color-text-primary: $-font-color-primary;
$--color-text-regular: $-font-color-primary;
$--color-text-secondary: $-font-color-secondary;
$--color-text-placeholder: $-font-color-secondary;

// icon font path, required
$--font-path: "~element-ui/lib/theme-chalk/fonts";
