



















































































































































@import "~vars";

.label-button {
  font-size: $-font-size-s;
  padding: 8px;
  float: right;
}
